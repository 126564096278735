<template>
  <div class="newDownloadGuide">
    <div class="bannerA">
      <img :src="require('assets/images/download-guide/banner.png')" alt="" />
    </div>

    <div class="tab">
      <div
        class="tab_item"
        :class="i == tabIndex ? 'isActive' : ''"
        v-for="(item, i) in tabList"
        :key="item.name"
        @click="tabIndex = i"
      >
        <img :src="i == tabIndex ? item.img : item.ashimg" alt="" />

        {{ item.name }}
        <div class="footer" v-if="item.footer != ''">{{ item.footer }}</div>
      </div>
    </div>
    <div class="item_content" v-if="tabIndex == 1">
      <div class="introduce">
        <div class="title">
          <span>小程序</span>
          成语文库
        </div>

        <div class="text">现已推出小程序版本，立即扫码体验</div>
        <!-- <div class="p">操作系统：win7/win8/win10/win11</div>
        <div class="p">Office：MS Word 2007/2010/2013/2016/2019/2021</div>
        <div class="p">WPS：WPS2010/2012/2016/2019</div>
        <div class="p">运行库需求：.Net FrameWork 4.0 和 VSTO 4.0</div> -->

        <div class="btnImg" @click="handleIdiomDown">
          <img :src="require('assets/images/download-guide/ewm.png')" alt="" />
          扫码即可使用
        </div>
      </div>
      <img
        style="width: 481px; height: 597px"
        :src="require('assets/images/download-guide/samall.png')"
        alt=""
      />
    </div>
    <div class="item_content" v-if="tabIndex == 2">
      <div class="introduce">
        <div class="title">
          <span>APP</span>
          成语office
        </div>

        <div class="text">现已推出APP版本，立即下载体验</div>
        <!-- <div class="p">操作系统：win7/win8/win10/win11</div>
        <div class="p">Office：MS Word 2007/2010/2013/2016/2019/2021</div>
        <div class="p">WPS：WPS2010/2012/2016/2019</div>
        <div class="p">运行库需求：.Net FrameWork 4.0 和 VSTO 4.0</div> -->

        <div class="btnImg1" @click="handleIdiomDown">
          <div>
            <img
              :src="require('assets/images/download-guide/ios1.png')"
              alt=""
            />
            <div class="box">
              <img
                :src="require('assets/images/download-guide/ph.png')"
                alt=""
              />
              <span>扫码下载使用</span>
            </div>
          </div>
          <div>
            <img
              :src="require('assets/images/download-guide/and2.png')"
              alt=""
            />
            <div class="box">
              <img
                :src="require('assets/images/download-guide/and.png')"
                alt=""
              /><span>扫码下载使用</span>
            </div>
          </div>
        </div>
      </div>
      <img
        style="width: 481px; height: 597px"
        :src="require('assets/images/download-guide/app.png')"
        alt=""
      />
    </div>
    <div class="item_content" v-if="tabIndex == 3">
      <div class="introduce">
        <div class="title">
          <span>PC系统</span>
          成语WPS/Office版
        </div>

        <div class="text">现已推出WPS/Office版本，立即下载体验</div>
        <div class="p">操作系统：win7/win8/win10/win11</div>
        <div class="p">Office：MS Word 2007/2010/2013/2016/2019/2021</div>
        <div class="p">WPS：WPS2010/2012/2016/2019</div>
        <div class="p">运行库需求：.Net FrameWork 4.0 和 VSTO 4.0</div>

        <div class="btn" @click="handleIdiomDown">
          <img
            :src="require('assets/images/download-guide/wps_bai.png')"
            alt=""
          />
          立即下载
        </div>
      </div>
      <img
        style="width: 535.3px"
        :src="require('assets/images/download-guide/bgc1.png')"
        alt=""
      />
    </div>
    <div class="item_content" v-if="tabIndex == 0">
      <div class="introduce">
        <div class="title">
          <span>PC系统</span>
          成语极速版
        </div>

        <div class="text">环境需求</div>
        <div class="p">操作系统：win7/win8/win10/win11</div>
        <div class="p">运行库需求：.Net 6</div>

        <div class="btn" @click="handleJSDownLoad">
          <img :src="require('assets/images/jishuWhite.png')" alt="" />
          Windows系统下载
        </div>
        <div class="btn" @click="testIng">
          <!-- <div class="test">(内测)</div> -->
          <img :src="require('assets/images/jishuWhite.png')" alt="" />
          麒麟系统下载(内测版)
        </div>
        <div class="btn" @click="testIng">
          <!-- <div class="test">(内测)</div> -->
          <img :src="require('assets/images/jishuWhite.png')" alt="" />
          Mac系统下载(内测版)
        </div>
      </div>
      <div class="img">
        <!-- <div class="text">成语极速版·主界面图</div> -->
        <img
          style="width: 517px; height: 73px"
          :src="require('assets/images/download-guide/bgc2.png')"
          alt=""
        />
      </div>
    </div>

    <commonFooter />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import commonFooter from "components/footer/commonFooter.vue";
export default {
  name: "newDownloadGuide",
  //import引入的组件需要注入到对象中才能使用
  components: {
    commonFooter,
  },
  data() {
    //这里存放数据
    return {
      tabIndex: 0,
      tabList: [
        {
          name: "PC系统—成语极速版",
          img: require("assets/images/jishu-active.png"),
          ashimg: require("assets/images/jishu.png"),
          footer: "【推荐下载使用】",
        },
        {
          name: "小程序—成语文库",
          img: require("assets/images/library1.png"),
          ashimg: require("assets/images/library.png"),
          footer: "【内测邀请体验】",
        },
        {
          name: "APP—成语office",
          img: require("assets/images/appoffice1.png"),
          ashimg: require("assets/images/appoffice.png"),
          footer: "【内测邀请体验】",
        },
        {
          name: "PC系统—成语WPS/Office版",
          img: require("assets/images/download-guide/wps.png"),
          ashimg: require("assets/images/download-guide/wps_ash.png"),
          footer: "【内测邀请体验】",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleIdiomDown() {
      
      window.location.href =
        "https://www.writemall.com/download/成语Office-WPS(windows系统).rar";
    },
    handleJSDownLoad() {
      const link = document.createElement("a");
      link.href = "https://qiniu.writemall.com/ChengyuSpeedEdition/%E6%88%90%E8%AF%AD%E6%9E%81%E9%80%9F%E7%89%88%28windows%E7%B3%BB%E7%BB%9F%29.zip";
      link.download = "download";
      link.click();
    },
    testIng() {
      this.$alert("邀请内测中，将尽快开放下载使用", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {},
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.params.tabIndex) {
      this.tabIndex = this.$route.params.tabIndex;
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.newDownloadGuide {
  width: 100%;
  position: absolute;
  left: 0;
  background-color: #fbf8fb;
  margin-top: -30px;

  .bannerA {
    height: 160px;
    width: 1190px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tab {
    background-color: #fff;
    padding: 20px 50px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 1190px;
    margin: 20px auto 0;
    border-bottom: 1px solid #e5e5e5;
    justify-content: space-evenly;

    .tab_item {
      cursor: pointer;
      display: flex;
      position: relative;
      align-items: center;
      padding-bottom: 25px;

      img {
        margin-right: 20px;
        width: 34px;
      }
      .footer {
        position: absolute;
        display: flex;
        justify-content: center;
        bottom: 5px;
        width: 100%;
        color: #409eff;
        font-size: 13px;
      }
    }

    .isActive {
      color: #ff6808;
      position: relative;
      border-bottom: 4px solid #ff6808;

      //   &::before {
      //     content: "";
      //     position: absolute;
      //     bottom: 0;
      //     left: calc(50% - 75px);
      //     width: 160px;
      //     height: 4px;
      //     background: #ff6900;
      //   }
    }
  }

  .item_content {
    width: 1190px;
    margin: 0px auto 20px;
    box-sizing: border-box;
    padding: 50px 70px;
    // padding: 108px 416px 146px 245px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .introduce {
      .title {
        font-family: Microsoft YaHei;
        font-size: 36px;
        // text-align: center;
        margin-bottom: 46px;
        span {
          color: #ff6900;
        }
      }
      .text {
        color: #999999;
        font-weight: bold;
        font-size: 20px;
        line-height: 34px;
      }
      .p {
        font-size: 20px;
        color: #999999;
        line-height: 34px;
      }
      .btnImg {
        width: 182px;
        height: 218px;
        margin: 41px auto 0;
        text-align: center;

        img {
          width: 100%;
          height: 182px;
          margin-bottom: 20px;
        }
      }
      .btnImg1 {
        width: 459px;
        height: 203px;
        margin-top: 54px;
        text-align: center;
        font-size: 18px;
        color: #333;
        display: flex;
        justify-content: space-between;
        .box {
          img {
            width: 34px;
            height: 34px;
            vertical-align: middle;
            margin-bottom: 0px;
            margin-right: 9px;
          }
        }
        img {
          width: 163px;
          height: 163px;
          margin-bottom: 20px;
        }
      }
      .btn {
        cursor: pointer;
        margin-top: 39px;
        width: 320px;
        height: 64px;
        background: #ff6900;
        border-radius: 5px;
        color: #fff;
        font-family: PingFang SC;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
          width: 34px;
          margin-right: 18px;
        }
        .test {
          color: #ff6900;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -25%;
        }
      }
    }
    .img {
      position: relative;
      .text {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
      }
    }
    img {
      width: 808px;
    }
  }
}
</style>
